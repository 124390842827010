import React from "react";
import firebase from "../../firebase";
import PostItem from "./PostItem";

const PostList = (props) => {
  const [posts, setPosts] = React.useState([]);
  const isTrending = props.location.pathname.includes("trending");

  React.useEffect(() => {
    const unsubscribe = getPosts();
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [isTrending]);

  function getPosts() {
    if (isTrending) {
      return firebase.db
        .collection("posts")
        .orderBy("voteCount", "desc")
        .onSnapshot(handleSnapshot);
    }
    return firebase.db
      .collection("posts")
      .orderBy("created", "desc")
      .onSnapshot(handleSnapshot);
  }

  function handleSnapshot(snapshot) {
    const posts = snapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    setPosts(posts);
  }

  return (
    <>
      {posts.map((post, index) => (
        <PostItem
          key={post.id}
          showCount={true}
          url={`/post/${post.id}`}
          post={post}
          index={post + 1}
        />
      ))}
    </>
  );
};

export default PostList;
