import React from "react";
import {
  IonPage,
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import firebase from "../firebase";

import { Plugins } from "@capacitor/core";
import UserContext from "../contexts/UserContext";
import NavHeader from "../components/Header/NavHeader";
import PostItem from "../components/Post/PostItem";
import PostComment from "../components/Post/PostComment";
import CommentModal from "../components/Post/CommentModal";

const { Browser } = Plugins;

const Post = (props) => {
  const { user } = React.useContext(UserContext);
  const [post, setPost] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const postId = props.match.params.postId;
  const postRef = firebase.db.collection("posts").doc(postId);

  React.useEffect(() => {
    getPost();
    // eslint-disable-next-line
  }, [postId]);

  function getPost() {
    postRef.get().then((doc) => {
      setPost({ ...doc.data(), id: doc.id });
    });
  }

  function handleOpenModal() {
    if (!user) {
      props.history.push("/login");
    } else {
      setShowModal(true);
    }
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleAddComment(commentText) {
    if (!user) {
      props.history.push("/login");
    } else {
      postRef.get().then((doc) => {
        if (doc.exists) {
          const previousComments = doc.data().comments;
          const newComment = {
            postedBy: { id: user.uid, name: user.displayName },
            created: Date.now(),
            text: commentText,
          };
          const updatedComments = [...previousComments, newComment];
          postRef.update({ comments: updatedComments });
          setPost((prevState) => ({
            ...prevState,
            comments: updatedComments,
          }));
        }
      });
      setShowModal(false);
    }
  }

  function handleAddVote() {
    if (!user) {
      props.history.push("/login");
    } else {
      postRef.get().then((doc) => {
        if (doc.exists) {
          const previousVotes = doc.data().votes;
          const vote = { votedBy: { id: user.uid, name: user.displayName } };
          const updatedVotes = [...previousVotes, vote];
          const voteCount = updatedVotes.length;
          postRef.update({ votes: updatedVotes, voteCount });
          setPost((prevState) => ({
            ...prevState,
            votes: updatedVotes,
            voteCount: voteCount,
          }));
        }
      });
    }
  }

  function handleDeletePost() {
    postRef
      .delete()
      .then(() => {
        console.log(`Document with ID ${post.id} deleted`);
      })
      .catch((err) => {
        console.error("Error deleting document:", err);
      });
    props.history.push("/");
  }

  function postedByAuthUser(post) {
    return user && user.uid === post.postedBy.id;
  }

  async function openBrowser() {
    await Browser.open({
      url: post.url,
    });
  }

  return (
    <IonPage>
      <NavHeader
        title={post && post.title}
        option={post && postedByAuthUser(post)}
        icon={closeCircleOutline}
        action={handleDeletePost}
      />
      <IonContent color="medium">
        <CommentModal
          isOpen={showModal}
          title="New Comment"
          sendAction={handleAddComment}
          closeAction={handleCloseModal}
        />
        {post && (
          <>
            <IonGrid>
              <IonRow>
                <IonCol class="ion-text-center">
                  <PostItem post={post} browser={openBrowser} />
                  <IonButton onClick={() => handleAddVote()} size="small">
                    Upvote
                  </IonButton>
                  <IonButton onClick={() => handleOpenModal()} size="small">
                    Comment
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {post.comments.map((comment, index) => (
              <PostComment
                key={index}
                comment={comment}
                post={post}
                setPost={setPost}
              />
            ))}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Post;
