import React from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
} from "@ionic/react";

import firebase from "../firebase";

import CommunityItem from "../components/Community/CommunityItem";

import NavHeader from "../components/Header/NavHeader";
import { add } from "ionicons/icons";
import PostItem from "../components/Post/PostItem";

const Community = (props) => {
  const [community, setCommunity] = React.useState(null);
  const [posts, setPosts] = React.useState([]);
  const communityId = props.match.params.communityId;
  const communityRef = firebase.db.collection("communities").doc(communityId);

  React.useEffect(() => {
    async function getData() {
      await getCommunity();
      await getInitialPosts();
    }

    function getCommunity() {
      communityRef.get().then((doc) => {
        setCommunity({ ...doc.data(), id: doc.id });
      });
    }

    function getInitialPosts() {
      firebase.db
        .collection("posts")
        .where("communityId", "==", communityId)
        .get()
        .then((snapshot) => {
          const posts = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          setPosts(posts);
        });
    }

    getData();
    // eslint-disable-next-line
  }, [communityId]);

  return (
    <IonPage>
      <NavHeader title={community && community.title} />
      <IonContent color="medium">
        {community && (
          <>
            <IonGrid>
              <IonRow>
                <IonCol class="ion-text-center">
                  <CommunityItem community={community} />
                </IonCol>
              </IonRow>
              {posts.map((post, index) => (
                <PostItem
                  key={post.id}
                  showCount={false}
                  post={post}
                  index={index}
                  url={`/post/${post.id}`}
                />
              ))}
            </IonGrid>
          </>
        )}
        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton href="/create-post">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default Community;
