export default function validateCreateLink(values) {
  let errors = {};

  // Description Errors
  if (!values.description) {
    errors.description = "A description is required.";
  } else if (values.description.length < 10) {
    errors.description = "The description must be at least 10 characters.";
  }
  // Description Errors
  if (!values.title) {
    errors.title = "A title is required.";
  } else if (values.title.length < 3) {
    errors.title = "The title must be at least 3 characters.";
  }

  return errors;
}
