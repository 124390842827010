import React from "react";
import firebase from "../../firebase";
import CommunityItem from "./CommunityItem";

const CommunityList = () => {
  const [communities, setCommunities] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = getCommunities();
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  function getCommunities() {
    return firebase.db.collection("communities").onSnapshot(handleSnapshot);
  }

  function handleSnapshot(snapshot) {
    const communities = snapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    setCommunities(communities);
  }

  return communities.map((community, index) => {
    const result = [
      <CommunityItem
        key={community.id}
        showCount={true}
        url={`/community/${community.id}`}
        community={community}
        index={index + 1}
      />,
    ];

    return result;
  });
};

export default CommunityList;
