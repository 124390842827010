import React from "react";
import {
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonIcon,
} from "@ionic/react";
import CommunityList from "../components/Community/CommunityList";
import SmallHeader from "../components/Header/SmallHeader";
import LargeHeader from "../components/Header/LargeHeader";
import { add } from "ionicons/icons";

const Communities = (props) => {
  return (
    <IonPage>
      <SmallHeader title="Communities" />
      <IonContent color="medium">
        <LargeHeader title="Communities" />
        <CommunityList />
        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton href="/submit">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default Communities;
