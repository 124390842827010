import React from "react";
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonBadge,
  IonCard,
  IonCardContent,
  IonList,
} from "@ionic/react";
import {
  chevronUpCircleOutline,
  chatbubbleEllipsesOutline,
  personCircleOutline,
  timeOutline,
} from "ionicons/icons";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

const PostItem = ({ post, index, showCount, url, browser }) => {
  return (
    <IonCard routerLink={url} button>
      <IonCardContent class="ion-no-padding">
        <IonList lines="none">
          <IonItem>
            <IonLabel>
              <IonBadge>{post.community.title}</IonBadge>
              <br></br>
              <br></br>
              <IonText>
                <div className="ion-text-wrap">
                  <strong style={{ fontSize: "1rem" }}>{post.title}</strong>
                </div>
              </IonText>
              <br></br>
              <IonText>
                <div className="ion-text-wrap" style={{ fontSize: "0.8rem" }}>
                  {post.description}
                </div>
              </IonText>
              <br></br>
              <p
                style={{
                  alignItems: "center",
                  fontSize: "0.8rem",
                  fontWeight: "normal",
                }}
              >
                <IonIcon
                  icon={chevronUpCircleOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {post.voteCount} points
                </IonText>
                {" | "}
                <IonIcon
                  icon={personCircleOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {post.postedBy.name}
                </IonText>
                {" | "}
                <IonIcon
                  icon={timeOutline}
                  style={{
                    verticalAlign: "middle",
                  }}
                />{" "}
                <IonText
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  {formatDistanceToNow(post.created)}
                </IonText>
                {post.comments.length > 0 && (
                  <>
                    {" | "}
                    <IonIcon
                      icon={chatbubbleEllipsesOutline}
                      style={{
                        verticalAlign: "middle",
                      }}
                    />{" "}
                    <IonText
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {post.comments.length} comments
                    </IonText>
                  </>
                )}{" "}
              </p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default PostItem;
