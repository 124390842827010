import React from "react";
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import useFormValidation from "../hooks/useFormValidation";
import validateCreatePost from "../components/Post/validateCreatePost";
import firebase from "../firebase";
import UserContext from "../contexts/UserContext";
import SmallHeader from "../components/Header/SmallHeader";
import LargeHeader from "../components/Header/LargeHeader";

const INITIAL_STATE = {
  description: "",
  title: "",
};

const Submit = (props) => {
  const { user } = React.useContext(UserContext);
  const { handleSubmit, handleChange, values } = useFormValidation(
    INITIAL_STATE,
    validateCreatePost,
    handleCreatePost
  );
  const [communities, setCommunities] = React.useState([]);
  const [community, setCommunity] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = getCommunities();
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  function getCommunities() {
    return firebase.db.collection("communities").onSnapshot(handleSnapshot);
  }

  function handleSnapshot(snapshot) {
    const communities = snapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    setCommunities(communities);
  }

  function handleCreatePost() {
    if (!user) {
      props.history.push("/login");
    } else {
      const { title, description } = values;
      const newPost = {
        title,
        description,
        postedBy: {
          id: user.uid,
          name: user.displayName,
        },
        communityId: community.id,
        community,
        voteCount: 1,
        votes: [
          {
            votedBy: { id: user.uid, name: user.displayName },
          },
        ],
        comments: [],
        created: Date.now(),
      };
      firebase.db.collection("posts").add(newPost);
      props.history.push("/");
    }
  }

  return (
    <IonPage>
      <SmallHeader title="Add Post" />
      <IonContent color="medium">
        <LargeHeader title="Add Post" />

        <IonItem>
          <IonLabel>Community</IonLabel>
          <IonSelect
            value={community}
            placeholder="Select Community"
            onIonChange={(e) => setCommunity(e.detail.value)}
          >
            {communities.map((item) => (
              <IonSelectOption key={item.id} value={item}>
                {item.title}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        <IonItem lines="full">
          <IonLabel position="floating">Title</IonLabel>
          <IonInput
            name="title"
            type="text"
            value={values.title}
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>

        <IonItem lines="full">
          <IonLabel position="floating">Description</IonLabel>
          <IonInput
            name="description"
            value={values.description}
            type="text"
            onIonChange={handleChange}
            required
          ></IonInput>
        </IonItem>

        <IonRow>
          <IonCol>
            <IonButton
              type="submit"
              color="primary"
              expand="block"
              onClick={handleSubmit}
            >
              Add Post
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Submit;
