const firebaseConfig = {
  apiKey: "AIzaSyBSDyZAxZ3Bez-ZqHdT7BKgQiFj2SzQT4c",
  authDomain: "forum-dd230.firebaseapp.com",
  databaseURL: "https://forum-dd230.firebaseio.com",
  projectId: "forum-dd230",
  storageBucket: "forum-dd230.appspot.com",
  messagingSenderId: "701370627336",
  appId: "1:701370627336:web:19654de40e0fa956ede8bd",
};

export default firebaseConfig;
