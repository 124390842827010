import React from "react";
import { withRouter } from "react-router-dom";
import {
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonList,
  IonThumbnail,
  IonImg,
} from "@ionic/react";

const CommunityItem = ({ url, community }) => {
  return (
    <IonCard routerLink={url} button>
      <IonCardContent class="ion-no-padding">
        <IonList lines="none">
          <IonItem>
            <IonThumbnail slot="start">
              <IonImg src={community.thumbnail} />
            </IonThumbnail>
            <IonLabel>
              <div className="ion-text-wrap">
                <strong style={{ fontSize: "1rem" }}>{community.title}</strong>
              </div>

              <div className="ion-text-wrap" style={{ fontSize: "0.8rem" }}>
                {community.description}
              </div>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default withRouter(CommunityItem);
